<template>
  <div
    class="csn-nmm"
    :class="{ 'csn-nmm-displayed': SHOWS_USER_NOTIFICATION_SIDE_MENU }"
  >
    <div class="csn-nmm-header">
      <button @click="goBackToMainMenu" class="csn-nmm-arrow-back">
        <SlideArrow />
      </button>

      <p class="csn-nmm-title">
        <span>{{ t('notifications') }}</span>
        <span
          v-if="hasNotifications"
          class="csn-notification-red-cirle csn-nmm-red-cirle"
        >
          {{ listLength }}
        </span>
      </p>
      <button @click="closeMenu" class="csn-notification-menu-close-btn">
        <CloseIcon />
      </button>
    </div>

    <div class="csn-nmm-list">
      <Loader v-if="IS_USER_NOTIFICATION_LIST_PENDING" />
      <span v-else-if="isEmptyList" class="csn-notification-empty-list">
        {{ t('empty_list') }}
      </span>
      <div
        v-for="{
          gameImage,
          name,
          amount,
          bonusCode,
          id,
          description,
          freeSpin,
          percent,
        } in USER_NOTIFICATION_LIST"
        v-else
        :key="id"
      >
        <UserNotification
          :gameImage="gameImage"
          :name="name"
          :amount="amount"
          :bonusCode="bonusCode"
          :description="description"
          :freeSpin="freeSpin"
          :percent="percent"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  USER_NOTIFICATIONS,
  Module,
  USER_NOTIFICATION_LIST,
  IS_USER_NOTIFICATION_LIST_PENDING,
  USER_NOTIFICATION_SIDE_MENU,
  TOGGLE_USER_NOTIFICATION_SIDE_MENU,
  TOGGLE_MAIN_MENU,
  SHOWS_USER_NOTIFICATION_SIDE_MENU,
} from '@/constants'
import { isEmpty } from '@/helpers'

export default {
  name: USER_NOTIFICATION_SIDE_MENU,
  components: {
    Loader: () => import('@/components/Loader'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    UserNotification: () =>
      import('@/components/UserNotifications/components/UserNotification'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  computed: {
    ...mapState(Module.MAIN, [SHOWS_USER_NOTIFICATION_SIDE_MENU]),
    ...mapState(Module.USER_NOTIFICATION, [
      USER_NOTIFICATION_LIST,
      IS_USER_NOTIFICATION_LIST_PENDING,
    ]),
    t() {
      return this.$createComponentTranslator(USER_NOTIFICATIONS)
    },
    isEmptyList() {
      return isEmpty(this.USER_NOTIFICATION_LIST)
    },
    hasNotifications() {
      return !this.IS_USER_NOTIFICATION_LIST_PENDING && !this.isEmptyList
    },
    listLength() {
      return Number(this.USER_NOTIFICATION_LIST?.length)
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_USER_NOTIFICATION_SIDE_MENU,
      TOGGLE_MAIN_MENU,
    ]),
    closeMenu() {
      this.TOGGLE_USER_NOTIFICATION_SIDE_MENU(false)
    },
    async goBackToMainMenu() {
      await this.TOGGLE_USER_NOTIFICATION_SIDE_MENU(false)
      this.TOGGLE_MAIN_MENU()
    },
  },
}
</script>
