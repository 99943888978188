var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"csn-nmm",class:{ 'csn-nmm-displayed': _vm.SHOWS_USER_NOTIFICATION_SIDE_MENU }},[_c('div',{staticClass:"csn-nmm-header"},[_c('button',{staticClass:"csn-nmm-arrow-back",on:{"click":_vm.goBackToMainMenu}},[_c('SlideArrow')],1),_c('p',{staticClass:"csn-nmm-title"},[_c('span',[_vm._v(_vm._s(_vm.t('notifications')))]),(_vm.hasNotifications)?_c('span',{staticClass:"csn-notification-red-cirle csn-nmm-red-cirle"},[_vm._v(" "+_vm._s(_vm.listLength)+" ")]):_vm._e()]),_c('button',{staticClass:"csn-notification-menu-close-btn",on:{"click":_vm.closeMenu}},[_c('CloseIcon')],1)]),_c('div',{staticClass:"csn-nmm-list"},[(_vm.IS_USER_NOTIFICATION_LIST_PENDING)?_c('Loader'):(_vm.isEmptyList)?_c('span',{staticClass:"csn-notification-empty-list"},[_vm._v(" "+_vm._s(_vm.t('empty_list'))+" ")]):_vm._l((_vm.USER_NOTIFICATION_LIST),function(ref){
      var gameImage = ref.gameImage;
      var name = ref.name;
      var amount = ref.amount;
      var bonusCode = ref.bonusCode;
      var id = ref.id;
      var description = ref.description;
      var freeSpin = ref.freeSpin;
      var percent = ref.percent;
return _c('div',{key:id},[_c('UserNotification',{attrs:{"gameImage":gameImage,"name":name,"amount":amount,"bonusCode":bonusCode,"description":description,"freeSpin":freeSpin,"percent":percent}})],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }